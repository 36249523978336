const source = [
    {
        'name': 'Проектная документация  на строительство, капитальный ремонт  или реконструкцию',
        'parts' : [
            'анализ исходных данных',
            'выезд на объект и рекогносцировка (при необходимости)',
            'проведение необходимых изысканий',
            'разработка типовых конструктивных решений',
            'проектирование площадки, разработка проекта организации строительства',
            'сметный расчёт',
            'планирование мероприятий по снижению воздействия на окружающую среду',
            'сопровождение согласования в надзорных органах'
        ]
    },
    {
        'name': 'Проекты мониторинга технического состояния сооружения',
        'parts' : [
            'анализ исходных данных',
            'определение периодичности и количества измерений на объекте',
            'составление календарного плана мониторинга',
            'подробное описание всех измерений',
            'составление схем с контрольно-измерительной аппаратурой сооружения',
            'требования к оборудованию',
            'предельные показатели технического состояния сооружения'
        ],
    },
    {
        'name': 'Разработка и установка геодезических сетей на сооружении',
        'parts' : [
            'анализ исходных данных',
            'выезд на объект и рекогносцировка (при необходимости)',
            'определение положения и количества опорных пунктов',
            'определение положения, типа и количества деформационных марок',
            'описание методики измерения',
            'определение периодичности измерений',
            'требования к оборудованию',
            'предельные показатели смещений',
        ]
    }
]
export default source;
