const source = [
    {
        'name': 'Проектирование и установка опорной и наблюдательной геодезических сетей.',  
    },
    {
        'name': 'Анализ полученных данных и составление отчётов о деформациях сооружения.',
    },
    {
        'name': 'Проведение высокоточных измерений с использованием современного оборудования.',
    },
    {
        'name': 'Рекомендации по устранению выявленных проблем и предотвращению возможных деформаций в будущем.',
    }
]
export default source;
